<!--资金需求列表-->
<template>
  <div class="resource">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/resource' }"
          >资源集市</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/enterpriseResource' }"
          >企业资源</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{$route.query.type?'项目需求':'资金需求'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="params-box w-1400">
      <div class="way-title clearfix">
        <div class="fl act"><router-link to="/enterpriseNeedZJList">资金需求</router-link></div>
        <div class="fl"><router-link to="/enterpriseNeedXMList">项目需求</router-link></div>
    </div>
      <div class="needclass clearfix">
        <label class="fl">资金需求分类</label>
        <i class="fl" style="margin-right: 20px">:</i>
        <div class=" fl" :class="{isactive:!params.filter.needType}"  @click="params.filter.needType='';getPageList()">全部</div>
        <ul class="clearfix fl search-list">
          <li v-for="item in FundNeeds" class="fl classify-item" :class="{isactive:params.filter.needType == item}" :key="item"   @click="params.filter.needType=item;getPageList()">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div class="w-1400">
      <el-row :gutter="40">
        <el-col :span="18">
          <div class="res-main">
            <div
              class="join-item clearfix"
              v-for="item in pageData.list"
              :key="item.id"
            >
              <router-link :to="`/enterpriseNeedZJDetail?id=${item.id}`">
                <div class="fl join-pic">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls"
                  ></el-image>
                </div>
                <div class="join-info fr">
                  <h4 class="ellipsis">{{ item.title }}</h4>
                  <div class="join-address">
                    <i class="el-icon-location"></i>
                    项目详情：{{regHtml(item.projectInfo)}}
                  </div>
                  <div class="join-intro">
                    <i class="el-icon-location"></i>
                    企业简介：{{regHtml(item.enterpriseInfo)}}
                  </div>
                </div>
              </router-link>
            </div>
            <el-pagination
              class="pagination"
              background
              layout="prev, pager, next,jumper"
              :total="pageData.total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="tj">
            <h4><i class="arrow-r"></i> 资金需求推荐</h4>
            <div v-if="tuijianpageData.length>0">
              <div
                class="tj-box clearfix"
                v-for="item in tuijianpageData"
                :key="item.id + 'tj'"
              >
                <router-link :to="`/enterpriseNeedZJDetail?id=${item.id}`">
                  <div class="tj-pic fl">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.photoUrls"
                    ></el-image>
                  </div>
                  <div class="tj-info fr">
                    <h4 class="ellipsis">{{ regHtml(item.title) }}</h4>
                    <div class="join-address">
                      <i class="el-icon-location"></i>
                      {{ regHtml(item.projectInfo) }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="empty" v-else>
              <el-empty description="暂无相关数据"></el-empty>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { GetEnterpriseNeedzj } from "@/api/ziyuanjishi"
import {regHtml} from '@/utils/reg'
export default {
  data() {
    return {
      FundNeeds:["投资","金融","资产处理","其他"],
      pageData: {},
      tuijianpageData: {},
      params: {
        currentPage: 1,
        pageSize: 10,
        filter: {
          title:"",
          needType: "",
        },
      },
    };
  },
  computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
  mounted() {
    this.getPageList();
    this.getuijianenterpriseList();
  },
  methods: {
    getPageList(){
            GetEnterpriseNeedzj(this.params).then((res)=>{
                this.pageData=res.data.data;
            })
    },
    getuijianenterpriseList() {
      const tuijianparams = {
        currentPage: 1,
        pageSize: 10,
        filter: { needType: "投资" },
      };
      GetEnterpriseNeedzj(tuijianparams).then((res) => {
        this.tuijianpageData = res.data.data.list;
      });
    },
    //分页
    handleCurrentChange(page) {
      this.params.currentPage = page;
      this.getPageList();
    },
  },
};
</script>
<style scoped>
.tj-info {
  width: calc(100% - 110px);
}
.act{
  color: #0e6ac1;   
}
.way-title .act a{
  color: #3e6ff7;  
  font-weight: bold;
}
.needclass{
  margin: 20px 0;
}
.isactive{
    /* background-color:#5c8ad7; */
    cursor: pointer;
}
.way-title div:last-child{
    border-left: 2px solid #999;
}
.way-title div:hover,.way-title div.act{
    color: #0e6ac1;
}
.way-title div{
    padding: 0px 20px;
    cursor: pointer;
}
.way-title div:first-child{
    padding-left: 0;
}
.way-title{
    height: 50px;
    font-size: 20px;
    border-bottom: 2px solid #0e6ac1;
}
.tj-box {
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.tj-pic {
  width: 100px;
  height: 100px;
  background: #5c8ad7;
  padding: 2px;
}
.tj .arrow-r {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../../assets/img/right2.png) no-repeat;
  background-size: 100% 100%;
  transform: translateY(2px);
}
.join-intro i {
  color: #0e69c2;
}
.join-intro {
  font-size: 16px;
  line-height: 30px;
  height: 72px;
  color: #666;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.join-address i {
  color: #0e69c2;
}
.join-address {
  font-size: 16px;
  line-height: 30px;
  height: 65px;
  color: #666;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.join-info h4 {
  line-height: 50px;
  font-size: 24px;
}
.join-info {
  width: calc(100% - 170px);
  height: 150px;
  overflow: hidden;
}
.join-pic {
  width: 150px;
  height: 150px;
  background: #3aced8;
  padding: 2px;
}
.join-item {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 6px;
}
.tj-title {
  line-height: 32px;
  font-size: 16px;
}
.tj-pic {
  height: 100px;
}
.tj h4 {
  font-size: 20px;
  line-height: 42px;
}
.tj {
  padding: 0 5px;
  border: 1px solid #ccc;
}
.res-price span:first-child {
  font-size: 20px;
  color: #fe620d;
}
.res-price {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  font-size: 16px;
  margin-top: 30px;
}
.res-mark span:nth-child(1) {
  background: #0e69c2;
}
.res-mark span:nth-child(2) {
  background: #fe620d;
}
.res-mark span:nth-child(3) {
  background: #3aced8;
}
.res-mark span {
  padding: 10px 20px;
  color: #fff;
  margin-right: 16px;
  font-size: 16px;
  display: inline-block;
}
.res-mark {
  margin-top: 30px;
  /* line-height: 20px; */
}
.res-info h5 {
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
}
.res-info h4 {
  font-size: 22px;
  height: 48px;
  line-height: 48px;
}
.res-info {
  width: calc(100% - 420px);
}
.res-pic {
  width: 390px;
  height: 250px;
}
.res-item {
  padding: 20px 0;
  border-top: 1px solid #ccc;
}
.classify-item {
  padding: 0px 20px;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}
.search-list {
  /* width: 700px; */
}
.params-box span.isactive,
.v-list-sort span.isactive,
.isactive {
  color: #4283d2;
}
.params-box {
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  /* font-family: '宋体'; */
}
.params-box div {
  font-size: 18px;
  font-weight: 500;
  /* height: 40px; */
  line-height: 30px;
}
.params-box label {
  width: 120px;
  text-align: justify;
  text-align-last: justify;
  font-weight: 550;
}
.resource {
  font-family: "微软雅黑";
  /* background: url(../../../assets/img/qylist-bg.png) no-repeat; */
  background-size: auto 300px;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>
